var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getLayoutOptions.usesAlternateTradeshowLayout)?_c('div',[_c('section',{staticClass:"pt-2",class:_vm.cssVariables['--tradeshow-header-bg-color']
                    ? 'bg-tradeshow-header'
                    : 'bg-white'},[_vm._m(0)]),_c('SponsorsByTiers')],1):_c('div',[_c('section',{staticClass:"pt-2",class:_vm.cssVariables['--tradeshow-header-bg-color']
                    ? 'bg-tradeshow-header'
                    : 'bg-white'},[_c('div',{staticClass:"container p-6 md:p-12"},[_c('h1',{staticClass:"title mg-title-header text-center has-dark-text mg-font-bold",class:_vm.getLayoutOptions.siteName == 'smacna'
                            ? 'text-white'
                            : ''},[_vm._v(" "+_vm._s(_vm.getConfigurableLabel.tradeShowHeader ? _vm.getConfigurableLabel.tradeShowHeader : "Find a Product or Vendor")+" ")])])]),_c('section',{staticClass:"section px-1 section pb-8",class:_vm.cssVariables['--tradeshow-content-bg-color']
                    ? 'bg-tradeshow-content'
                    : 'bg-white'},[_c('div',{staticClass:"container mx-18"},[_c('div',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column is-size-6 is-center is-two-fifths separator-right flex flex-col justify-center items-center"},[_c('h2',{staticClass:"title mg-font-bold mg-font-title-lg mg-text-title-color"},[_vm._v(" "+_vm._s(_vm.getConfigurableLabel.featuredColumnHeader ? _vm.getConfigurableLabel.featuredColumnHeader : "FEATURED PARTNERS")+" ")]),_vm._l((_vm.featuredPartners),function(partner){return _c('div',{key:partner.name,staticClass:"text-white-xxx w-fit-content pt-6"},[_c('exhibitor-logo-card',{staticClass:"separator px-4 mb-4 mg-border",attrs:{"id":partner.id,"name":partner.name,"imgPath":partner.imgPath}})],1)})],2),_c('div',{staticClass:"column column flex flex-col"},[_c('h2',{staticClass:"title mg-font-bold mg-font-title-lg mg-text-title-color"},[_vm._v(" "+_vm._s(_vm.getConfigurableLabel.searchColumnHeader ? _vm.getConfigurableLabel.searchColumnHeader : "FIND A PRODUCT OR VENDOR")+" ")]),_c('exhibitor-search-form',{staticClass:"mt-12"})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container p-6 md:p-12"},[_c('div',{staticClass:"text-primary block m-auto text-center text-lg w-3/4"},[_c('h1',{staticClass:"title mg-title-header text-center has-dark-text mg-font-bold"},[_vm._v(" Thank you to our sponsors. ")]),_c('p',[_vm._v(" For additional resources and information on each company, please click the appropriate logo below. ")])])])}]

export { render, staticRenderFns }